.button {
  font-family: 'Quicksand', sans-serif;
  background: #ee0979;
  background: -webkit-linear-gradient(to right, #ff6a00, #ee0979);
  background: linear-gradient(to right, #ff6a00, #ee0979);
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.875rem 1.5rem;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 250ms;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.button:hover {
  transform: scale(1.1);
}

.button:active {
  transform: scale(1);
}

@media (max-width: 480px) {
  .button {
    font-size: 0.9rem;
  }
}
