.section {
  min-height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f9;
  color: #100e19;
  scroll-margin-top: 5rem;
}

@media (max-width: 480px) {
  .section {
    min-height: calc(100vh - 4rem);
    scroll-margin-top: 4rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  gap: 4rem;
  margin-bottom: 5rem;
}

@media (max-width: 480px) {
  .container {
    gap: 2rem;
    margin-bottom: 4rem;
  }
}

.content {
  display: flex;
  gap: 3rem;
}

.about-text {
  width: 50%;
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.tech-skills {
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  row-gap: 3rem;
  flex-wrap: wrap;
}

.tech-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  gap: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.icon {
  height: 3.5rem;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
}

.highlight {
  color: black;
  font-weight: 600;
}

@media (max-width: 480px) {
  .content {
    flex-direction: column;
  }

  .about-text {
    width: 100%;
    text-align: center;
    gap: 0;
  }

  .tech-skills {
    width: 100%;
    gap: 1rem;
    row-gap: 2.2rem;
  }

  .tech-item {
    height: 4rem;
    width: 4rem;
    gap: 0.8rem;
    font-size: 0.8rem;
  }

  .icon {
    height: 2.5rem;
  }

  .contact {
    display: none;
  }
}
