.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 12rem;
  gap: 0.5rem;
  background-color: #100e19;
  color: #bdb7be;
}

.socials {
  font-size: 2.2rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.icon {
  transition: transform 200ms ease-in-out;
}

.icon:hover {
  transform: scale(1.2);
  color: white;
}

.copyright {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
}
