.section {
  position: relative;
  min-height: calc(100vh - 5rem);
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  align-content: end;
  background-color: #f6f5f9;
  color: #100e19;
  scroll-margin-top: 5rem;
}

@media (max-width: 480px) {
  .section {
    min-height: calc(100vh - 4rem);
    scroll-margin-top: 4rem;
  }
}

.content {
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  gap: 4rem;
  margin-bottom: 5rem;
}

@media (max-width: 480px) {
  .content {
    gap: 2rem;
    margin-bottom: 4rem;
  }
}

.form {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 60%;
  padding: 2.5rem;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 600;
  font-size: 1.2rem;
}

.input {
  padding: 0.6rem;
  margin-top: 0.6rem;
  border-radius: 0.5rem;
  border: none;
  background-color: rgb(240, 240, 240);
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;
  resize: none;
  color: #100e19;
}

.input:focus {
  outline: none;
}

.invalid {
  background-color: #ee097821;
}

.message {
  font-size: 0.9rem;
}

.hidden {
  visibility: hidden;
}

.submit {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .form {
    width: 100%;
    padding: 1.5rem;
    gap: 0.5rem;
  }
  .label {
    font-size: 1rem;
  }
}

.spacer {
  aspect-ratio: 900/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('/public/svg/bottom-wave.svg');
  grid-row-start: 2;
  align-self: end;
  margin-bottom: -1px;
}

@media (min-width: 992px) {
  .spacer {
    aspect-ratio: 3000/300;
    background-image: url('/public/svg/long-bottom-wave.svg');
  }
}
