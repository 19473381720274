.section {
  min-height: calc(100vh - 5rem);
  scroll-margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f5f9;
  color: #100e19;
}

@media (max-width: 480px) {
  .section {
    min-height: calc(100vh - 4rem);
    scroll-margin-top: 4rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  gap: 4rem;
}

@media (max-width: 480px) {
  .container {
    gap: 2rem;
  }
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 10rem;
  margin-bottom: 10rem;
}

@media (max-width: 480px) {
  .projects {
    gap: 3rem;
    margin-bottom: 3rem;
  }
}
