.nav {
  width: 100%;
  height: 5rem;
  padding: 0 10vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #100e19;
  color: #bdb7be;
  z-index: 2;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.nav-links li a {
  font-size: 1.05rem;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
}

.nav-links li a:hover {
  cursor: pointer;
  color: white;
}

.nav-links li a:after {
  content: '';
  position: absolute;
  background-color: white;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}

.nav-links li a:hover:after {
  width: 100%;
}

.logo {
  font-size: 1.25rem;
  font-weight: 600;
}

.logo:hover {
  color: white;
}

.burger {
  display: none;
  z-index: 1;
}

.icon {
  font-size: 1.5rem;
}

@media (max-width: 480px) {
  .nav {
    height: 4rem;
    padding: 0 5vw;
  }

  .nav-links {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: -80vw;
    min-height: 100vh;
    width: 80vw;
    gap: 2rem;
    padding-top: 8rem;
    background-color: #100e19;
    transition: 0.3s ease-in-out;
  }

  .nav-links.clicked {
    right: 0;
  }

  .burger {
    display: block;
  }
}

@media (min-width: 469px) and (max-width: 768px) {
  .nav {
    padding: 0 4vw;
  }
}
