.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 3rem 6rem;
}

@media (max-width: 1200px) {
  .project {
    gap: 4rem;
  }
}

@media (max-width: 480px) {
  .project {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.introduction {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.project-heading {
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.highlight {
  color: #ee0979;
}

.description {
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 50%;
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 4rem;
}

@media (max-width: 1200px) {
  .images {
    gap: 1rem;
    padding: 0 2rem;
  }
}

.project-img:nth-child(2) {
  margin-top: 2rem;
}

.project-img {
  width: 100%;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.project-links {
  display: flex;
  gap: 2rem;
}

.project-link {
  font-size: 1.1rem;
  font-weight: 600;
}

.project-link:hover {
  color: #ee0979;
}

.detail {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  color: #ee0979;
  line-height: 1.5rem;
  letter-spacing: 0.15rem;
}

.sub-heading {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.technologies {
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 50%;
}

.paragraphs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paragraph {
  line-height: 1.5rem;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 1rem;
}

.icon {
  height: 5.5rem;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
}

@media (max-width: 992px) {
  .icon {
    height: 3.5rem;
  }
}
