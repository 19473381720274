@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Quicksand', sans-serif;
  background-color: #100e19;
  color: #bdb7be;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

h2 {
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-weight: 400;
  padding-top: 1rem;
}

@media (max-width: 480px) {
  h2 {
    font-size: 3rem;
  }
}
