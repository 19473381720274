.section {
  position: relative;
  min-height: calc(100vh - 5rem);
  display: grid;
  grid-template-rows: 1fr auto 2fr;
  justify-items: center;
  scroll-margin-top: 5rem;
}

@media (max-width: 480px) {
  .section {
    min-height: calc(100vh - 4rem);
    scroll-margin-top: 4rem;
  }
}

.content {
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  gap: 2rem;
}

@media (max-width: 480px) {
  .content {
    gap: 1.5rem;
  }
}

.leading {
  font-size: 1.8rem;
  font-weight: 500;
  background: #ee0979;
  background: -webkit-linear-gradient(to right, #ff6a00, #ee0979);
  background: linear-gradient(to right, #ff6a00, #ee0979);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  width: fit-content;
}

.heading {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
}

.sub-heading {
  font-size: 3rem;
  font-weight: 600;
}

.button {
  width: fit-content;
}

.spacer {
  aspect-ratio: 1200/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('/public/svg/top-wave.svg');
  grid-row-start: 3;
  align-self: end;
  margin-bottom: -1px;
}

@media (min-width: 992px) {
  .spacer {
    aspect-ratio: 3000/300;
    background-image: url('/public/svg/long-top-wave.svg');
  }
}

@media (max-width: 480px) {
  .leading {
    font-size: 1.3rem;
  }
  .heading {
    font-size: 2.6rem;
  }
  .sub-heading {
    font-size: 1.5rem;
  }
}
